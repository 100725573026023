import React from "react";
import JotformEmbed from "react-jotform-embed";
import { useRecoilState } from "recoil";
import { languageAtom } from "../Atoms";

function BI() {
  const [language] = useRecoilState(languageAtom);
  return (
    <div className="container-fluid container-md p-0 service-full">
      <h1 className="text-center">Business Intelligence</h1>
      <div className="container-md-fluid container p-md-0">
        <p>
          {language ? (
            <span>
              A menudo tienes muchos datos, pero el tiempo que te tardas en
              procesarlos en hojas electrónicas y generar presentaciones, hacen
              que cuando la información esté lista, ya esté obsoleta y no sirva
              para tomar decisiones efectivamente.
            </span>
          ) : (
            <span>
              You often have <strong>loads</strong> of data, but the time it
              takes to process them <strong>into</strong> spreadsheets and
              slides{" "}
              <strong>
                leads the information to become obsolete and unuseful when
                making decisions effectively.
              </strong>
            </span>
          )}
        </p>
        <p>
          {language ? (
            <span>
              Nosotros te ayudamos a procesarla con{" "}
              <strong>herramientas</strong> de business intelligence que hará
              que la información esté disponible cuando se necesita de una
              manera automatizada y a la mano.
            </span>
          ) : (
            <span>
              We <strong>will</strong> help you process them with
              business-intelligence tools that will make information available
              when necessary in an automated and handy way.
            </span>
          )}
        </p>
      </div>

      <div className="cuestionario">
        <JotformEmbed src="https://form.jotform.com/221906768033862" />
      </div>

      <div className="container-fluid container-md p-md-0">
        <div className="watermark-cover mt-4 py-5 text-center">
          <h1>
            {language ? (
              <span>Artículos que te podrían interesar:</span>
            ) : (
              <span>Here are some articles related</span>
            )}
          </h1>
        </div>
        <div className="external-links d-block d-md-flex justify-content-around my-4">
          <div className="card mb-4">
            <img src="images/link-bi-1.png"></img>
            <a href="https://cincodias.elpais.com/cincodias/2022/07/18/companias/1658136368_309877.html">
              <div className="card-body">
                <h5 class="card-title">
                  El dato como potencial para el negocio y el cliente
                </h5>
                <p class="card-text">
                  El mundo vive sobre un aluvión permanente de referencias. Los
                  expertos tienen el reto de buscar la información que aporta
                  valor al usuario y a la empresa.
                </p>
                <span>elpais.com</span>
              </div>
            </a>
          </div>
          <div className="card mb-4">
            <img src="images/link-bi-2.png"></img>
            <a href="http://www.rrhhdigital.com/secciones/actualidad/153603/Las-empresas-impulsadas-por-el-analisis-de-datos-obtienen-un-70-mas-de-ingresos-por-empleado">
              <div className="card-body">
                <h5 class="card-title">
                  Impulsadas por el análisis de datos obtienen un 70% más...
                </h5>
                <p class="card-text">
                  Las empresas impulsadas por datos obtienen un 70% más de
                  ingresos por empleado y un 22% más de beneficios que aquellas
                  que no basan su toma de decisiones en el uso de los datos, así
                  se advierte en el estudio elaborado por Capgemini.
                </p>
                <span>rrhhdigital.com</span>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BI;
