import React from "react";
import { useRecoilState } from "recoil";
import { languageAtom } from "../Atoms";

export default function Contact(props) {
  const [language] = useRecoilState(languageAtom);
  return (
    <div
      id="contacto"
      className="container-fluid position-relative px-0 min-vh-50 vh-100 d-flex flex-column justify-content-center"
    >
      <div className="d-flex flex-column align-items-center">
        <h1 className="mb-4 text-center">
          {language ? (
            <span>Queremos escuchar tus necesidades</span>
          ) : (
            <span>We want to reach out to you</span>
          )}
        </h1>
        <div>
          {language ? (
            <span>
              <div className="elfsight-app-e997c0fc-bca3-4e61-92d2-7bfdd7bf0c3c"></div>
            </span>
          ) : (
            <span>
              <script
                src="https://apps.elfsight.com/p/platform.js"
                defer
              ></script>
              <div class="elfsight-app-638a035a-aef7-40f9-8a4c-d8a48b7da67d"></div>
            </span>
          )}
        </div>
      </div>
    </div>
  );
}
