// import { Link } from "react-router-dom";
import React from "react";
import { useRecoilState } from "recoil";
import { languageAtom } from "../Atoms";
import AutoplayVideoBI from "./AutoplayVideoBI";
import AutoplayVideoConsultoria from "./AutoplayVideoConsultoria";
import AutoplayVideoDataScience from "./AutoplayVideoDataScience";
import AutoplayVideoProjectM from "./AutoplayVideoProjectM";

export default function Services(props) {
  const [language] = useRecoilState(languageAtom);
  return (
    <div
      id="servicios"
      className="container-fluid container-lg position-relative pt-5 px-0 vh-100 d-flex flex-column align-items-center justify-content-center"
    >
      <div className="accordion accordion-flush w-100" id="accordionExample">
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingTwo">
            <button
              className="accordion-button me-3"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseTwo"
              aria-expanded="true"
              aria-controls="collapseTwo"
            >
              Data Science
            </button>
          </h2>
          <div
            id="collapseTwo"
            className="accordion-collapse collapse show"
            aria-labelledby="headingTwo"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body overflow-hidden ps-md-0 py-md-0 d-flex flex-column">
              <div className="row">
                <div className="col-md-4 d-none d-md-block p-0">
                  <div className="mini-video">
                    <AutoplayVideoDataScience></AutoplayVideoDataScience>
                  </div>
                </div>
                <div className="container col-md-8 col-xs-12 p-1 p-md-3 d-flex flex-column align-items-start">
                  <h1 className="display-5 mx-auto">
                    {language ? (
                      <span>Moldeando el futuro</span>
                    ) : (
                      <span>Shaping the future</span>
                    )}
                  </h1>
                  <p>
                    {language ? (
                      <span>
                        La información es conocimiento, sin embargo, a menudo
                        tenemos muchos datos que no nos dicen nada. Ese es
                        nuestro trabajo.
                      </span>
                    ) : (
                      <span>
                        Information is knowledge, <strong>but</strong> we often
                        have too much data that tell us nothing. That is our
                        job.
                      </span>
                    )}
                  </p>
                  <p>
                    {language ? (
                      <span>
                        Con el mejor equipo de{" "}
                        <strong>científicos de datos</strong> utilizando
                        herramientas de inteligencia artificial y aplicando
                        algoritmos estadísticos, podremos transformar tus datos
                        en información valiosa para la{" "}
                        <strong>Toma de Decisiones</strong>.
                      </span>
                    ) : (
                      <span>
                        <strong>Bearing</strong> the best{" "}
                        <strong>data-scientists team </strong>
                        using AI tools and applying statistical algorithms, we
                        can transform your data into valuable information for
                        Decision Making.
                      </span>
                    )}
                  </p>
                  <p>
                    {language ? (
                      <span>
                        Esto hará que tu inversión se recupere de una manera muy
                        veloz y tengas el conocimiento de tu negocio que siempre
                        has querido.
                      </span>
                    ) : (
                      <span>
                        That will make your investment recover quickly, and you
                        will <strong>get</strong> the knowledge{" "}
                        <strong>from</strong> your business that you have always
                        wanted.
                      </span>
                    )}
                  </p>
                  {/* <Link
                    to={"/DS"}
                    // reloadDocument
                    className="px-3 py-1 mt-1 mx-auto"
                  >
                    Conoce más
                  </Link> */}
                  <a
                    className="px-3 py-1 mt-1 mx-auto"
                    aria-current="page"
                    href="/DS"
                  >
                    {language ? (
                      <span>Conoce más</span>
                    ) : (
                      <span>Learn more</span>
                    )}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="accordion-item">
          <h2 className="accordion-header" id="headingThree">
            <button
              className="accordion-button collapsed me-3"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseThree"
              aria-expanded="false"
              aria-controls="collapseThree"
            >
              {language ? (
                <span>Dirección de Proyectos</span>
              ) : (
                <span>Project Management</span>
              )}
            </button>
          </h2>
          <div
            id="collapseThree"
            className="accordion-collapse collapse"
            aria-labelledby="headingThree"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body overflow-hidden ps-md-0 py-md-0 d-flex flex-column">
              <div className="row">
                <div className="col-md-4 d-none d-md-block p-0">
                  <div className="mini-video">
                    <AutoplayVideoProjectM></AutoplayVideoProjectM>
                  </div>
                </div>
                <div className="container col-md-8 col-xs-12 p-1 p-md-3 d-flex flex-column align-items-start">
                  <h1 className="display-5 mx-auto">
                    {language ? (
                      <span>El rumbo correcto</span>
                    ) : (
                      <span>The right course</span>
                    )}
                  </h1>
                  <p>
                    {language ? (
                      <span>
                        Definir un proyecto es “fácil”, sin embargo, ejecutarlo
                        es realmente difícil.
                      </span>
                    ) : (
                      <span>
                        Defining a project is "easy". However, executing it is
                        <strong> certainly</strong> difficult.
                      </span>
                    )}
                  </p>
                  <p>
                    {language ? (
                      <span>
                        En BAS hemos combinado el mejor equipo de{" "}
                        <strong>administradores de proyectos</strong> (PMs) y
                        las mejores herramientas tecnológicas para ayudarte a
                        controlar y medir tus proyectos, generando una
                        comunicación fluida y efectiva en tu organización.
                      </span>
                    ) : (
                      <span>
                        At BAS we have combined the best team of Project
                        Managers (PMs) <strong>with</strong> the best
                        technological tools in order to help you control and
                        measure your projects, generating fluent and effective
                        communication <strong>for</strong> your organization.
                      </span>
                    )}
                  </p>
                  <p>
                    {language ? (
                      <span>
                        De este modo te podrás concentrar en tomar decisiones
                        del proyecto en vez de dar seguimiento a los
                        involucrados. Ese es nuestro trabajo.
                      </span>
                    ) : (
                      <span>
                        <strong>By doing this</strong>, you{" "}
                        <strong>will be able to </strong>
                        concentrate on making project decisions instead of
                        following up on those involved. That is our job.
                      </span>
                    )}
                  </p>
                  {/* <Link
                    to={"/PM"}
                    // reloadDocument
                    className="px-3 py-1 mt-1 mx-auto"
                  >
                    Conoce más
                  </Link> */}
                  <a
                    className="px-3 py-1 mt-1 mx-auto"
                    aria-current="page"
                    href="/PM"
                  >
                    {language ? (
                      <span>Conoce más</span>
                    ) : (
                      <span>Learn more</span>
                    )}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="accordion-item">
          <h2 className="accordion-header" id="headingFour">
            <button
              className="accordion-button collapsed me-3"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseFour"
              aria-expanded="false"
              aria-controls="collapseFour"
            >
              {language ? <span>Consultoría</span> : <span>Consultancy</span>}
            </button>
          </h2>
          <div
            id="collapseFour"
            className="accordion-collapse collapse"
            aria-labelledby="headingFour"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body overflow-hidden ps-md-0 py-md-0 d-flex flex-column">
              <div className="row">
                <div className="col-md-4 d-none d-md-block p-0">
                  <div className="mini-video">
                    <AutoplayVideoConsultoria></AutoplayVideoConsultoria>
                  </div>
                </div>
                <div className="container col-md-8 col-xs-12 p-1 p-md-3 d-flex flex-column align-items-start">
                  <h1 className="display-5 mx-auto">
                    {language ? (
                      <span>Estamos para ayudarte</span>
                    ) : (
                      <span>We are here to help you</span>
                    )}
                  </h1>
                  <p>
                    {language ? (
                      <span>
                        El empresario debe crear valor y perdurar en el tiempo.
                      </span>
                    ) : (
                      <span>
                        <strong>An</strong> entrepreneur must create value and
                        endure over time.
                      </span>
                    )}
                  </p>
                  <p>
                    {language ? (
                      <span>
                        Esto para asegurarse que los empleados, clientes,
                        proveedores, accionistas y gobierno, sigan recibiendo
                        los beneficios que genera la empresa, y así, tener una
                        mejor sociedad.
                      </span>
                    ) : (
                      <span>
                        That is to ensure that employees, customers, suppliers,
                        shareholders and the government continue to receive the
                        benefits generated by <strong>an enterprise</strong>,
                        and thus have a better society.
                      </span>
                    )}
                  </p>
                  <p>
                    {language ? (
                      <span>
                        En BAS tenemos un <strong>equipo de consultores</strong>{" "}
                        en diferentes áreas que te ayudarán a definir el rumbo a
                        seguir para lograr este propósito.
                      </span>
                    ) : (
                      <span>
                        At BAS we have a team of consultants in different areas
                        that will help you define the course{" "}
                        <strong>that will make you </strong>
                        achieve this goal.
                      </span>
                    )}
                  </p>
                  {/* <Link
                    to={"/CO"}
                    // reloadDocument
                    className="px-3 py-1 mt-1 mx-auto"
                  >
                    Conoce más
                  </Link> */}
                  <a
                    className="px-3 py-1 mt-1 mx-auto"
                    aria-current="page"
                    href="/CO"
                  >
                    {language ? (
                      <span>Conoce más</span>
                    ) : (
                      <span>Learn more</span>
                    )}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="accordion-item">
          <h2 className="accordion-header" id="headingOne">
            <button
              className="accordion-button collapsed me-3"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseOne"
              aria-expanded="false"
              aria-controls="collapseOne"
            >
              Business Intelligence
            </button>
          </h2>
          <div
            id="collapseOne"
            className="accordion-collapse collapse"
            aria-labelledby="headingOne"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body overflow-hidden ps-md-0 py-md-0 d-flex flex-column">
              <div className="row">
                <div className="col-md-4 d-none d-md-block p-0">
                  <div className="mini-video">
                    <AutoplayVideoBI></AutoplayVideoBI>
                  </div>
                </div>
                <div className="container col-md-8 col-xs-12 p-1 p-md-3 d-flex flex-column align-items-start">
                  <h1 className="display-5 mx-auto">
                    {language ? (
                      <span>Los datos a tu favor</span>
                    ) : (
                      <span>The data in your favor</span>
                    )}
                  </h1>
                  <p>
                    {language ? (
                      <span>
                        A menudo tienes muchos datos, pero el tiempo que te
                        tardas en procesarlos en hojas electrónicas y generar
                        presentaciones, hacen que cuando la información esté
                        lista, ya esté obsoleta y no sirva para tomar decisiones
                        efectivamente.
                      </span>
                    ) : (
                      <span>
                        You often have <strong>loads</strong> of data, but the
                        time it takes to process them <strong>into</strong>{" "}
                        spreadsheets and slides{" "}
                        <strong>
                          leads the information to become obsolete and unuseful
                          when making decisions effectively.
                        </strong>
                      </span>
                    )}
                  </p>
                  <p>
                    {language ? (
                      <span>
                        Nosotros te ayudamos a procesarla con{" "}
                        <strong>herramientas</strong> de business intelligence
                        que hará que la información esté disponible cuando se
                        necesita de una manera automatizada y a la mano.
                      </span>
                    ) : (
                      <span>
                        We <strong>will</strong> help you process them with
                        business-intelligence tools that will make information
                        available when necessary in an automated and handy way.
                      </span>
                    )}
                  </p>
                  {/* <Link
                    to={"/BI"}
                    // reloadDocument
                    className="px-3 py-1 mt-1 mx-auto"
                  >
                    Conoce más
                  </Link> */}
                  <a
                    className="px-3 py-1 mt-1 mx-auto"
                    aria-current="page"
                    href="/BI"
                  >
                    {language ? (
                      <span>Conoce más</span>
                    ) : (
                      <span>Learn more</span>
                    )}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
