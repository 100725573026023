// import { Link } from "react-router-dom";
import React from "react";
import { RecoilRoot, useRecoilState } from "recoil";
import { languageAtom } from "../Atoms";

function ToggleLanguage() {
  const [language, setLanguage] = useRecoilState(languageAtom);
  return (
    <div>
      <button className="toggleLanguage" onClick={() => setLanguage(!language)}>
        {language ? <span>English</span> : <span>Español</span>}
      </button>
    </div>
  );
}

export default function NavBar(props) {
  const [language] = useRecoilState(languageAtom);
  return (
    <nav className="navbar navbar-expand-lg fixed-top navbar-dark p-0">
      <div className="container">
        <a
          className="logo navbar-brand nav-link d-flex align-items-center"
          href="/#inicio"
        >
          <img
            className="me-2"
            src="images/BAS-Icon.svg"
            alt="BAS icon"
            height="35"
          ></img>
          <div className="d-flex flex-column">
            <span>Business</span>
            <span>Autonomy</span>
            <span>Solutions</span>
          </div>
        </a>
        <div className="navbar-nav navbar-right flex-row">
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              <li className="nav-item mx-2">
                {/* <Link
                  to={"/#inicio"}
                  className="links nav-link active"
                  aria-current="page"
                >
                  Inicio
                </Link> */}
                <a
                  className="links nav-link"
                  aria-current="page"
                  href="/#inicio"
                >
                  {language ? <span>Inicio</span> : <span>Home</span>}
                </a>
              </li>
              <li className="nav-item mx-2">
                <a
                  className="links nav-link"
                  aria-current="page"
                  href="/#servicios"
                >
                  {language ? <span>Servicios</span> : <span>Services</span>}
                </a>
              </li>
              <li className="nav-item mx-2">
                <a
                  className="links nav-link"
                  aria-current="page"
                  href="/#sectores"
                >
                  {language ? <span>Sectores</span> : <span>Sectors</span>}
                </a>
              </li>
              <li className="nav-item ms-2">
                <a
                  className="links nav-link me-3"
                  aria-current="page"
                  href="/#contacto"
                >
                  {language ? <span>Contacto</span> : <span>Contact</span>}
                </a>
              </li>
              <li className="d-flex align-items-center">
                <ToggleLanguage />
              </li>
              {/* <li className="nav-item">
                <a
                  className="cuestionario nav-link px-3"
                  aria-current="page"
                  href="#"
                >
                  Cuestionario
                </a>
              </li> */}
            </ul>
          </div>
        </div>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
      </div>
    </nav>
  );
}
