import React from "react";
import { useRecoilState } from "recoil";
import { languageAtom } from "../Atoms";

export default function Sectors(props) {
  const [language] = useRecoilState(languageAtom);
  return (
    <div
      id="sectores"
      className="container-fluid position-relative px-0 pt-3 min-vh-50 vh-100 d-flex flex-column justify-content-center"
    >
      <h1 className="mt-5 mb-3 text-center display-5">
        {language ? (
          <span>Sectores en los que tenemos presencia</span>
        ) : (
          <span>Sectors in which we are present</span>
        )}
      </h1>
      <div className="tech-slideshow">
        <div className="mover-1 d-flex justify-content-center">
          <div className="card text-center">
            <div className="img-container">
              <img
                src="images/fundamentales.png"
                className="card-img-top"
                alt="..."
              ></img>
            </div>
            <div className="cad-body">
              <h2 className="card-title mt-3">
                {language ? <span>Generales</span> : <span>General</span>}
              </h2>
              <div className="card-text text-start px-3">
                <p>
                  {language ? (
                    <span>
                      Ayudamos a nuestros clientes con sus procesos
                      fundamentales del negocio, tal como procesos estratégicos,
                      actividades de home office, gestión de las ventas (CRM),
                      marketing, cobranza, control de cadena de suministros,
                      etc.
                    </span>
                  ) : (
                    <span>
                      We help our clients with their fundamental processes of
                      the business, such as strategic processes, activities of
                      home office, sales management (CRM), marketing,
                      collection, supply chain control, etc.
                    </span>
                  )}
                </p>
              </div>
            </div>
          </div>

          <div className="card text-center">
            <div className="img-container">
              <img
                src="images/construccion.png"
                className="card-img-top"
                alt="..."
              ></img>
            </div>
            <div className="cad-body">
              <h2 className="card-title mt-3">
                {language ? (
                  <span>Construcción</span>
                ) : (
                  <span>Building industry</span>
                )}
              </h2>
              <div className="card-text text-start px-3">
                <p>
                  {language ? (
                    <span>
                      Sabemos que este sector requiere de muchos procesos, desde
                      la gestoría, diseño arquitectónico, control de obra,
                      proveedores, decoración y mantenimiento. Con nuestros
                      servicios, les proveemos a nuestros clientes de la
                      información necesaria para gestionar cada paso y poder
                      anticipar demoras e ineficiencias.
                    </span>
                  ) : (
                    <span>
                      We know this sector requires many processes such as
                      management, architectural design, construction control,
                      suppliers, decoration, and maintenance. With our services,
                      we provide our clients with the necessary information to
                      manage each step and be able to anticipate and prevent
                      delays or any inefficient outcome.
                    </span>
                  )}
                </p>
              </div>
            </div>
          </div>

          <div className="card text-center">
            <div className="img-container">
              <img
                src="images/startUp.png"
                className="card-img-top"
                alt="..."
              ></img>
            </div>
            <div className="cad-body">
              <h2 className="card-title mt-3">Start-Up</h2>
              <div className="card-text text-start px-3">
                <p>
                  {language ? (
                    <span>
                      Cuando de emprendimiento se trata, es básico tener planes
                      de acción de la estrategia a seguir, por lo que nuestros
                      servicios apoyan directamente esta labor fundamental para
                      el logro de la empresa, desde la definición del modelo de
                      negocios, desarrollo de productos, así como los planes
                      paralelos a estos.
                    </span>
                  ) : (
                    <span>
                      When it comes to entrepreneurship, it is essential to have
                      action plans and strategies. Our services directly support
                      this fundamental task for{" "}
                      <strong>your company to achieve goals</strong>, from the
                      definition of the business model, and product development
                      to plans parallel to these.
                    </span>
                  )}
                </p>
              </div>
            </div>
          </div>

          <div className="card text-center">
            <div className="img-container">
              <img
                src="images/moda.png"
                className="card-img-top"
                alt="..."
              ></img>
            </div>
            <div className="cad-body">
              <h2 className="card-title mt-3">
                {language ? <span>Servicios</span> : <span>Services</span>}
              </h2>
              <div className="card-text text-start px-3">
                <p>
                  {language ? (
                    <span>
                      Servicios tales como diseño de productos, campañas
                      publicitarias, producciones fotográficas, coordinación de
                      exhibiciones, seguridad privada, consultoría, etc., son
                      procesos complejos con muchos responsables, interacciones,
                      dependencias y fechas de entrega sin tolerancia. En estos
                      procesos agregamos valor a nuestros clientes con el
                      control que hacemos y la anticipación que producimos.
                    </span>
                  ) : (
                    <span>
                      Services such as product design, <strong>campaign</strong>{" "}
                      advertising, <strong>photography </strong> productions,
                      exhibition coordination, private security,{" "}
                      <strong>and </strong> consulting{" "}
                      <strong>among others </strong> are complex processes with{" "}
                      <strong>a number of </strong> managers, interactions,
                      dependencies, and <strong>deadlines </strong> with{" "}
                      <strong>no </strong>
                      tolerance. In such processes, we add value to our clients
                      with the control that we do and the anticipation that we
                      produce.
                    </span>
                  )}
                </p>
              </div>
            </div>
          </div>

          <div className="card text-center">
            <div className="img-container">
              <img
                src="images/manufactura.png"
                className="card-img-top"
                alt="..."
              ></img>
            </div>
            <div className="cad-body">
              <h2 className="card-title mt-3">
                {language ? <span>Manufactura</span> : <span>Manufacture</span>}
              </h2>
              <div className="card-text text-start px-3">
                <p>
                  {language ? (
                    <span>
                      La producción de bienes (farmacéutica, químicos,
                      alimentos, ropa, calzado, muebles, empaques, etiquetas,
                      etc.), así como la mayoría de procesos en la manufactura,
                      como aumentos de capacidad, mejora en productividad, etc.,
                      son procesos y proyectos que si no se controlan, se llegan
                      a demorar muchísimo y provocan grandes pérdidas, por eso
                      le ayudamos a nuestros clientes a monitorear esto y darle
                      la información necesaria para que pueda anticipar demoras
                      en los objetivos.
                    </span>
                  ) : (
                    <span>
                      The production of goods (pharmaceutical, chemical, food,
                      clothing, footwear, furniture, packaging, labeling, etc.),
                      and most manufacturing processes such as capacity{" "}
                      <strong>growth and </strong>productivity improvement{" "}
                      <strong>among others </strong>are processes and projects
                      that{" "}
                      <strong>
                        take a long time and cause great losses if not
                        controlled.{" "}
                      </strong>
                      That is why we help our clients monitor this and provide
                      them with the <strong>necessary </strong> information.
                    </span>
                  )}
                </p>
              </div>
            </div>
          </div>

          <div className="card text-center">
            <div className="img-container">
              <img
                src="images/agricultura.png"
                className="card-img-top"
                alt="..."
              ></img>
            </div>
            <div className="cad-body">
              <h2 className="card-title mt-3">
                {language ? <span>Agricultura</span> : <span>Agriculture</span>}
              </h2>
              <div className="card-text text-start px-3">
                <p>
                  {language ? (
                    <span>
                      Todas las industrias tienen procesos o factores que no se
                      pueden controlar porque dependen del clima, suelo, etc.,
                      sin embargo, se tienen muchos otros que se deben de
                      controlar para asegurar el mejor rendimiento de las
                      cosechas y tener los insumos, mano de obra, y demás
                      factores que se añaden para lograr el objetivo. Por
                      ejemplo, los procesos de reclutamiento de personal,
                      compras, construcción de albergues, viveros, etc.
                    </span>
                  ) : (
                    <span>
                      <strong>Every </strong> industry <strong>has </strong>{" "}
                      processes or factors that cannot be controlled{" "}
                      <strong>since </strong> they depend on the weather, soil,
                      and <strong>other natural elements. </strong> However,{" "}
                      <strong>many others aspects must </strong>be controlled to
                      ensure the best crop yield and have the inputs, labor, and
                      other factors that are added to achieve the desired
                      objective.
                    </span>
                  )}
                </p>
              </div>
            </div>
          </div>

          <div className="card text-center">
            <div className="img-container">
              <img
                src="images/retail.png"
                className="card-img-top"
                alt="..."
              ></img>
            </div>
            <div className="cad-body">
              <h2 className="card-title mt-3">Retail</h2>
              <div className="card-text text-start px-3">
                <p>
                  {language ? (
                    <span>
                      Desde tiendas de productos gourmet, hasta grandes
                      superficies con miles de productos de moda, damos soporte
                      a nuestros clientes con sus procesos de marketing, mejora
                      continua, apertura de tiendas, etc.
                    </span>
                  ) : (
                    <span>
                      From gourmet product stores to superstores With thousands
                      of fashion products, we support our customers with their
                      marketing processes, continuous improvement, store
                      openings, etc.
                    </span>
                  )}
                </p>
              </div>
            </div>
          </div>

          <div className="card text-center">
            <div className="img-container">
              <img
                src="images/fundamentales.png"
                className="card-img-top"
                alt="..."
              ></img>
            </div>
            <div className="cad-body">
              <h2 className="card-title mt-3">
                {language ? <span>Generales</span> : <span>General</span>}
              </h2>
              <div className="card-text text-start px-3">
                <p>
                  {language ? (
                    <span>
                      Ayudamos a nuestros clientes con sus procesos
                      fundamentales del negocio, tal como procesos estratégicos,
                      actividades de home office, gestión de las ventas (CRM),
                      marketing, cobranza, control de cadena de suministros,
                      etc.
                    </span>
                  ) : (
                    <span>
                      We help our clients with their fundamental processes of
                      the business, such as strategic processes, activities of
                      home office, sales management (CRM), marketing,
                      collection, supply chain control, etc.
                    </span>
                  )}
                </p>
              </div>
            </div>
          </div>

          <div className="card text-center">
            <div className="img-container">
              <img
                src="images/construccion.png"
                className="card-img-top"
                alt="..."
              ></img>
            </div>
            <div className="cad-body">
              <h2 className="card-title mt-3">
                {language ? (
                  <span>Construcción</span>
                ) : (
                  <span>Building industry</span>
                )}
              </h2>
              <div className="card-text text-start px-3">
                <p>
                  {language ? (
                    <span>
                      Sabemos que este sector requiere de muchos procesos, desde
                      la gestoría, diseño arquitectónico, control de obra,
                      proveedores, decoración y mantenimiento. Con nuestros
                      servicios, les proveemos a nuestros clientes de la
                      información necesaria para gestionar cada paso y poder
                      anticipar demoras e ineficiencias.
                    </span>
                  ) : (
                    <span>
                      We know this sector requires many processes such as
                      management, architectural design, construction control,
                      suppliers, decoration, and maintenance. With our services,
                      we provide our clients with the necessary information to
                      manage each step and be able to anticipate and prevent
                      delays or any inefficient outcome.
                    </span>
                  )}
                </p>
              </div>
            </div>
          </div>

          <div className="card text-center">
            <div className="img-container">
              <img
                src="images/startUp.png"
                className="card-img-top"
                alt="..."
              ></img>
            </div>
            <div className="cad-body">
              <h2 className="card-title mt-3">Start-Up</h2>
              <div className="card-text text-start px-3">
                <p>
                  {language ? (
                    <span>
                      Cuando de emprendimiento se trata, es básico tener planes
                      de acción de la estrategia a seguir, por lo que nuestros
                      servicios apoyan directamente esta labor fundamental para
                      el logro de la empresa, desde la definición del modelo de
                      negocios, desarrollo de productos, así como los planes
                      paralelos a estos.
                    </span>
                  ) : (
                    <span>
                      When it comes to entrepreneurship, it is essential to have
                      action plans and strategies. Our services directly support
                      this fundamental task for{" "}
                      <strong>your company to achieve goals</strong>, from the
                      definition of the business model, and product development
                      to plans parallel to these.
                    </span>
                  )}
                </p>
              </div>
            </div>
          </div>

          <div className="card-end text-center">
            <div className="img-container">
              <img
                src="images/moda.png"
                className="card-img-top"
                alt="..."
              ></img>
            </div>
            <div className="cad-body">
              <h2 className="card-title mt-3">
                {language ? <span>Servicios</span> : <span>Services</span>}
              </h2>
              <div className="card-text text-start px-3">
                <p>
                  {language ? (
                    <span>
                      Servicios tales como diseño de productos, campañas
                      publicitarias, producciones fotográficas, coordinación de
                      exhibiciones, seguridad privada, consultoría, etc., son
                      procesos complejos con muchos responsables, interacciones,
                      dependencias y fechas de entrega sin tolerancia. En estos
                      procesos agregamos valor a nuestros clientes con el
                      control que hacemos y la anticipación que producimos.
                    </span>
                  ) : (
                    <span>
                      Services such as product design, <strong>campaign</strong>{" "}
                      advertising, <strong>photography </strong> productions,
                      exhibition coordination, private security,{" "}
                      <strong>and </strong> consulting{" "}
                      <strong>among others </strong> are complex processes with{" "}
                      <strong>a number of </strong> managers, interactions,
                      dependencies, and <strong>deadlines </strong> with{" "}
                      <strong>no </strong>
                      tolerance. In such processes, we add value to our clients
                      with the control that we do and the anticipation that we
                      produce.
                    </span>
                  )}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        id="carouselExampleControls"
        className="carousel slide d-none"
        data-bs-ride="carousel"
      >
        <div className="carousel-inner">
          <div className="carousel-item active">
            <div className="card text-center">
              <div className="img-container">
                <img
                  src="images/fundamentales.png"
                  className="card-img-top"
                  alt="..."
                ></img>
              </div>
              <div className="cad-body">
                <h2 className="card-title mt-3">
                  {language ? <span>Generales</span> : <span>General</span>}
                </h2>
                <div className="card-text text-start px-3">
                  <p>
                    {language ? (
                      <span>
                        Ayudamos a nuestros clientes con sus procesos
                        fundamentales del negocio, tal como procesos
                        estratégicos, actividades de home office, gestión de las
                        ventas (CRM), marketing, cobranza, control de cadena de
                        suministros, etc.
                      </span>
                    ) : (
                      <span>
                        We help our clients with their fundamental processes of
                        the business, such as strategic processes, activities of
                        home office, sales management (CRM), marketing,
                        collection, supply chain control, etc.
                      </span>
                    )}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="carousel-item">
            <div className="card text-center">
              <div className="img-container">
                <img
                  src="images/construccion.png"
                  className="card-img-top"
                  alt="..."
                ></img>
              </div>
              <div className="cad-body">
                <h2 className="card-title mt-3">
                  {language ? (
                    <span>Construcción</span>
                  ) : (
                    <span>Building industry</span>
                  )}
                </h2>
                <div className="card-text text-start px-3">
                  <p>
                    {language ? (
                      <span>
                        Sabemos que este sector requiere de muchos procesos,
                        desde la gestoría, diseño arquitectónico, control de
                        obra, proveedores, decoración y mantenimiento. Con
                        nuestros servicios, les proveemos a nuestros clientes de
                        la información necesaria para gestionar cada paso y
                        poder anticipar demoras e ineficiencias.
                      </span>
                    ) : (
                      <span>
                        We know this sector requires many processes such as
                        management, architectural design, construction control,
                        suppliers, decoration, and maintenance. With our
                        services, we provide our clients with the necessary
                        information to manage each step and be able to
                        anticipate and prevent delays or any inefficient
                        outcome.
                      </span>
                    )}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="carousel-item">
            <div className="card text-center">
              <div className="img-container">
                <img
                  src="images/startUp.png"
                  className="card-img-top"
                  alt="..."
                ></img>
              </div>
              <div className="cad-body">
                <h2 className="card-title mt-3">Start-Up</h2>
                <div className="card-text text-start px-3">
                  <p>
                    {language ? (
                      <span>
                        Cuando de emprendimiento se trata, es básico tener
                        planes de acción de la estrategia a seguir, por lo que
                        nuestros servicios apoyan directamente esta labor
                        fundamental para el logro de la empresa, desde la
                        definición del modelo de negocios, desarrollo de
                        productos, así como los planes paralelos a estos.
                      </span>
                    ) : (
                      <span>
                        When it comes to entrepreneurship, it is essential to
                        have action plans and strategies. Our services directly
                        support this fundamental task for{" "}
                        <strong>your company to achieve goals</strong>, from the
                        definition of the business model, and product
                        development to plans parallel to these.
                      </span>
                    )}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="carousel-item">
            <div className="card text-center">
              <div className="img-container">
                <img
                  src="images/moda.png"
                  className="card-img-top"
                  alt="..."
                ></img>
              </div>
              <div className="cad-body">
                <h2 className="card-title mt-3">
                  {language ? <span>Servicios</span> : <span>Services</span>}
                </h2>
                <div className="card-text text-start px-3">
                  <p>
                    {language ? (
                      <span>
                        Servicios tales como diseño de productos, campañas
                        publicitarias, producciones fotográficas, coordinación
                        de exhibiciones, seguridad privada, consultoría, etc.,
                        son procesos complejos con muchos responsables,
                        interacciones, dependencias y fechas de entrega sin
                        tolerancia. En estos procesos agregamos valor a nuestros
                        clientes con el control que hacemos y la anticipación
                        que producimos.
                      </span>
                    ) : (
                      <span>
                        Services such as product design,{" "}
                        <strong>campaign</strong> advertising,{" "}
                        <strong>photography </strong> productions, exhibition
                        coordination, private security, <strong>and </strong>{" "}
                        consulting <strong>among others </strong> are complex
                        processes with <strong>a number of </strong> managers,
                        interactions, dependencies, and{" "}
                        <strong>deadlines </strong> with <strong>no </strong>
                        tolerance. In such processes, we add value to our
                        clients with the control that we do and the anticipation
                        that we produce.
                      </span>
                    )}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="carousel-item">
            <div className="card text-center">
              <div className="img-container">
                <img
                  src="images/manufactura.png"
                  className="card-img-top"
                  alt="..."
                ></img>
              </div>
              <div className="cad-body">
                <h2 className="card-title mt-3">
                  {language ? (
                    <span>Manufactura</span>
                  ) : (
                    <span>Manufacture</span>
                  )}
                </h2>
                <div className="card-text text-start px-3">
                  <p>
                    {language ? (
                      <span>
                        La producción de bienes (farmacéutica, químicos,
                        alimentos, ropa, calzado, muebles, empaques, etiquetas,
                        etc.), así como la mayoría de procesos en la
                        manufactura, como aumentos de capacidad, mejora en
                        productividad, etc., son procesos y proyectos que si no
                        se controlan, se llegan a demorar muchísimo y provocan
                        grandes pérdidas, por eso le ayudamos a nuestros
                        clientes a monitorear esto y darle la información
                        necesaria para que pueda anticipar demoras en los
                        objetivos.
                      </span>
                    ) : (
                      <span>
                        The production of goods (pharmaceutical, chemical, food,
                        clothing, footwear, furniture, packaging, labeling,
                        etc.), and most manufacturing processes such as capacity{" "}
                        <strong>growth and </strong>productivity improvement{" "}
                        <strong>among others </strong>are processes and projects
                        that{" "}
                        <strong>
                          take a long time and cause great losses if not
                          controlled.{" "}
                        </strong>
                        That is why we help our clients monitor this and provide
                        them with the <strong>necessary </strong> information.
                      </span>
                    )}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="carousel-item">
            <div className="card text-center">
              <div className="img-container">
                <img
                  src="images/agricultura.png"
                  className="card-img-top"
                  alt="..."
                ></img>
              </div>
              <div className="cad-body">
                <h2 className="card-title mt-3">
                  {language ? (
                    <span>Agricultura</span>
                  ) : (
                    <span>Agriculture</span>
                  )}
                </h2>
                <div className="card-text text-start px-3">
                  <p>
                    {language ? (
                      <span>
                        Todas las industrias tienen procesos o factores que no
                        se pueden controlar porque dependen del clima, suelo,
                        etc., sin embargo, se tienen muchos otros que se deben
                        de controlar para asegurar el mejor rendimiento de las
                        cosechas y tener los insumos, mano de obra, y demás
                        factores que se añaden para lograr el objetivo. Por
                        ejemplo, los procesos de reclutamiento de personal,
                        compras, construcción de albergues, viveros, etc.
                      </span>
                    ) : (
                      <span>
                        <strong>Every </strong> industry <strong>has </strong>{" "}
                        processes or factors that cannot be controlled{" "}
                        <strong>since </strong> they depend on the weather,
                        soil, and <strong>other natural elements. </strong>{" "}
                        However, <strong>many others aspects must </strong>be
                        controlled to ensure the best crop yield and have the
                        inputs, labor, and other factors that are added to
                        achieve the desired objective.
                      </span>
                    )}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="carousel-item">
            <div className="card text-center">
              <div className="img-container">
                <img
                  src="images/retail.png"
                  className="card-img-top"
                  alt="..."
                ></img>
              </div>
              <div className="cad-body">
                <h2 className="card-title mt-3">Retail</h2>
                <div className="card-text text-start px-3">
                  <p>
                    {language ? (
                      <span>
                        Desde tiendas de productos gourmet, hasta grandes
                        superficies con miles de productos de moda, damos
                        soporte a nuestros clientes con sus procesos de
                        marketing, mejora continua, apertura de tiendas, etc.
                      </span>
                    ) : (
                      <span>
                        From gourmet product stores to superstores With
                        thousands of fashion products, we support our customers
                        with their marketing processes, continuous improvement,
                        store openings, etc.
                      </span>
                    )}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <button
          className="carousel-control-prev d-none"
          type="button"
          data-bs-target="#carouselExampleControls"
          data-bs-slide="prev"
        >
          <span
            className="carousel-control-prev-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button
          className="carousel-control-next d-none"
          type="button"
          data-bs-target="#carouselExampleControls"
          data-bs-slide="next"
        >
          <span
            className="carousel-control-next-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Next</span>
        </button>
      </div>

      {/* <div className="tech-slideshow-back-container">
        <div className="tech-slideshow-back">
          <div className="mover-2 d-flex">
            <div className="back-card"></div>
            <div className="back-card"></div>
            <div className="back-card"></div>
            <div className="back-card"></div>
            <div className="back-card"></div>
            <div className="back-card"></div>
            <div className="back-card"></div>
            <div className="back-card"></div>
            <div className="back-card"></div>
          </div>
        </div>
      </div> */}
    </div>
  );
}
