import React from "react";
import JotformEmbed from "react-jotform-embed";
import { useRecoilState } from "recoil";
import { languageAtom } from "../Atoms";


function CO() {
  const [language] = useRecoilState(languageAtom);
  return (
    <div className="container-fluid container-md p-0 service-full">
      <h1 className="text-center">
        {language ? <span>Consultoría</span> : <span>Consultancy</span>}
      </h1>
      <div className="container-md-fluid container p-md-0">
        <p>
          {language ? (
            <span>El empresario debe crear valor y perdurar en el tiempo.</span>
          ) : (
            <span>
              <strong>An</strong> entrepreneur must create value and endure over
              time.
            </span>
          )}
        </p>
        <p>
          {language ? (
            <span>
              Esto para asegurarse que los empleados, clientes, proveedores,
              accionistas y gobierno, sigan recibiendo los beneficios que genera
              la empresa, y así, tener una mejor sociedad.
            </span>
          ) : (
            <span>
              That is to ensure that employees, customers, suppliers,
              shareholders and the government continue to receive the benefits
              generated by <strong>an enterprise</strong>, and thus have a
              better society.
            </span>
          )}
        </p>
        <p>
          {language ? (
            <span>
              En BAS tenemos un <strong>equipo de consultores</strong> en
              diferentes áreas que te ayudarán a definir el rumbo a seguir para
              lograr este propósito.
            </span>
          ) : (
            <span>
              At BAS we have a team of consultants in different areas that will
              help you define the course <strong>that will make you </strong>
              achieve this goal.
            </span>
          )}
        </p>
      </div>

      <div className="cuestionario">
        <JotformEmbed src="https://form.jotform.com/221907053496863" />
      </div>

      <div className="container-fluid container-md p-md-0">
        <div className="watermark-cover mt-4 py-5 text-center">
          <h1>
            {language ? (
              <span>Artículos que te podrían interesar:</span>
            ) : (
              <span>Here are some articles related</span>
            )}
          </h1>
        </div>
        <div className="external-links d-block d-md-flex justify-content-around my-4">
          <div className="card mb-4">
            <img src="images/link-co-1.png"></img>
            <a href="https://revistaconsultoria.com.mx/que-es-consultoria/">
              <div className="card-body">
                <h5 class="card-title">¿Qué es Consultoría?</h5>
                <p class="card-text">
                  La consultoría se puede considerar como el paso siguiente
                  académico o de preparación acabando una licenciatura, una
                  maestría o un doctorado...
                </p>
                <span>revistaconsultoria.com</span>
              </div>
            </a>
          </div>
          <div className="card mb-4">
            <img src="images/link-co-2.png"></img>
            <a href="https://rockcontent.com/es/blog/tipos-de-consultoria/">
              <div className="card-body">
                <h5 class="card-title">
                  Conoce los principales tipos de consultoría...
                </h5>
                <p class="card-text">
                  La consultoría es un servicio profesional destinado a resolver
                  un problema de tu empresa, ayudándola a detectar falencias y
                  lograr el aprovechamiento de distintas oportunidades para su
                  crecimiento.
                </p>
                <span>rockcontent.com</span>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CO;
