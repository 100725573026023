import React from 'react'
import Footer from "./Footer";
import Header from "./Header";
import Services from "./Services";
import Sectors from "./Sectors";
import Contact from "./Contact";

function Home() {
  return (
    <>
      <Header></Header>
      <Services></Services>
      <Sectors></Sectors>
      <Contact></Contact>
      <Footer></Footer>
    </>
  );
}

export default Home