import React from "react";
import JotformEmbed from "react-jotform-embed";
import { useRecoilState } from "recoil";
import { languageAtom } from "../Atoms";

function PM() {
  const [language] = useRecoilState(languageAtom);
  return (
    <div className="container-fluid container-md p-0 service-full">
      <h1 className="text-center">
        {language ? (
          <span>Dirección de Proyectos</span>
        ) : (
          <span>Project Management</span>
        )}
      </h1>
      <div className="container-md-fluid container p-md-0">
        <p>
          {language ? (
            <span>
              Definir un proyecto es “fácil”, sin embargo, ejecutarlo es
              realmente difícil.
            </span>
          ) : (
            <span>
              Defining a project is "easy". However, executing it is
              <strong> certainly</strong> difficult.
            </span>
          )}
        </p>
        <p>
          {language ? (
            <span>
              En BAS hemos combinado el mejor equipo de{" "}
              <strong>administradores de proyectos</strong> (PMs) y las mejores
              herramientas tecnológicas para ayudarte a controlar y medir tus
              proyectos, generando una comunicación fluida y efectiva en tu
              organización.
            </span>
          ) : (
            <span>
              At BAS we have combined the best team of Project Managers (PMs){" "}
              <strong>with</strong> the best technological tools in order to
              help you control and measure your projects, generating fluent and
              effective communication <strong>for</strong> your organization.
            </span>
          )}
        </p>
        <p>
          {language ? (
            <span>
              De este modo te podrás concentrar en tomar decisiones del proyecto
              en vez de dar seguimiento a los involucrados. Ese es nuestro
              trabajo.
            </span>
          ) : (
            <span>
              <strong>By doing this</strong>, you{" "}
              <strong>will be able to </strong>
              concentrate on making project decisions instead of following up on
              those involved. That is our job.
            </span>
          )}
        </p>
      </div>

      <div className="cuestionario">
        <JotformEmbed src="https://form.jotform.com/221905652319860" />
      </div>

      <div className="container-fluid container-md p-md-0">
        <div className="watermark-cover mt-4 py-5 text-center">
          <h1>
            {language ? (
              <span>Artículos que te podrían interesar:</span>
            ) : (
              <span>Here are some articles related</span>
            )}
          </h1>
        </div>
        <div className="external-links d-block d-md-flex justify-content-around my-4">
          <div className="card mb-4">
            <img src="images/link-pm-1.png"></img>
            <a href="https://economipedia.com/definiciones/administracion-de-proyectos.html">
              <div className="card-body">
                <h5 class="card-title">Administración de proyectos</h5>
                <p class="card-text">
                  A través de la administración de proyectos, es posible hacer
                  más eficiente la gestión de los recursos empleados en el
                  mismo.
                </p>
                <span>economipedia.com</span>
              </div>
            </a>
          </div>
          <div className="card mb-4">
            <img src="images/link-pm-2.png"></img>
            <a href="https://www.pmi.org/learning/library/project-offices-best-practices-4519">
              <div className="card-body">
                <h5 class="card-title">
                  Best practices help ride out the storm
                </h5>
                <p class="card-text">
                  Until project management becomes an ingrained part of company
                  culture, the project office has a critical role to play-even
                  in stormy times.
                </p>
                <span>pmi.org</span>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PM;
