import React from "react";
import { useRecoilState } from "recoil";
import { languageAtom } from "../Atoms";

export default function Footer(props) {
  const [language] = useRecoilState(languageAtom);
  return (
    <div className="container-fluid footer-dark">
      <footer>
        <div>
          {/* <div className="row w-100 text-center d-flex justify-content-between">
            <div className="col item">
              <h3>Servicios</h3>
              <ul>
                <li>
                  <a href="#servicios">Business Intelligence</a>
                </li>
                <li>
                  <a href="#servicios">Data Science</a>
                </li>
                <li>
                  <a href="#servicios">Dirección de Proyectos</a>
                </li>
                <li>
                  <a href="#servicios">Consultoría</a>
                </li>
              </ul>
            </div>
            <div className="col item">
              <h3>Ubicacion</h3>
              <ul>
                <li>
                  <a
                    href="https://goo.gl/maps/yhKSGgZjaygeCTT17"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Fray Antonio Alcalde 10<br></br>
                    44100<br></br>
                    Zona Centro<br></br>
                    Guadalajara, Jal.
                  </a>
                </li>
              </ul>
            </div>
          </div> */}
          <div className="row item text text-center m-md-5 m-0 px-md-5 px-0">
            <h3>Business Autonomy Solutions</h3>
            <p>
              {language ? (
                <span>
                  Somos tu mejor opción para optimizar tus tiempos en lo que
                  realmente te importa
                </span>
              ) : (
                <span>
                  We are the best choice for optimizing your time at what really
                  matters to you
                </span>
              )}
            </p>
          </div>
          {/* <div className="d-flex  justify-content-center">
            <a
              href="https://wa.me/5213319856884"
              target="_blank"
              rel="noreferrer"
              className="me-5"
            >
              <img
                className="contact-logo"
                src="images/whatsapp-logo.svg"
                alt="whatsapp logo"
              ></img>
            </a>
            <a href="mailto:info@basoln.com?" className="ms-5">
              <img
                className="contact-logo"
                src="images/gmail-logo.svg"
                alt="gmail logo"
              ></img>
            </a>
          </div> */}
          <p className="copyright d-flex justify-content-center">
            ACEDEVS © 2022
          </p>
        </div>
      </footer>
    </div>
  );
}
