import React from 'react'
import { Outlet } from 'react-router';

import NavBar from "./NavBar";

function Layout(props) {
  return (
    <div className="main container-fluid p-0">
      <NavBar></NavBar>
      <a
        href="https://wa.me/5213319856884"
        target="_blank"
        rel="noreferrer"
        id="fixed-whatsapp"
      >
        <img
          className="fixed-whatsapp-logo"
          src="images/whatsapp-logo.png"
          alt="whatsapp logo"
        ></img>
      </a>
      <main className="container-fluid px-0">
        <Outlet />
      </main>
      <div className="area">
        <ul className="circles">
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
        </ul>
      </div>
    </div>
  );
}

export default Layout