import React from "react";

const AutoplayVideoProjectM = () => (
  <div
    dangerouslySetInnerHTML={{
      __html: `
        <video
          muted
          autoplay
          loop
          playsinline
          src="video/project-management-new.mp4"
          type="video/mp4"
        >
        </video> `,
    }}
  />
);

export default AutoplayVideoProjectM;
