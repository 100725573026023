import React from "react";
import JotformEmbed from "react-jotform-embed";
import { useRecoilState } from "recoil";
import { languageAtom } from "../Atoms";

function DS() {
  const [language] = useRecoilState(languageAtom);
  return (
    <div className="container-fluid container-md p-0 service-full">
      <h1 className="text-center">Data Science</h1>
      <div className="container-md-fluid container p-md-0">
        <p>
          {language ? (
            <span>
              La información es conocimiento, sin embargo, a menudo tenemos
              muchos datos que no nos dicen nada. Ese es nuestro trabajo.
            </span>
          ) : (
            <span>
              Information is knowledge, <strong>but</strong> we often have too
              much data that tell us nothing. That is our job.
            </span>
          )}
        </p>
        <p>
          {language ? (
            <span>
              Con el mejor equipo de <strong>científicos de datos</strong>{" "}
              utilizando herramientas de inteligencia artificial y aplicando
              algoritmos estadísticos, podremos transformar tus datos en
              información valiosa para la <strong>Toma de Decisiones</strong>.
            </span>
          ) : (
            <span>
              <strong>Bearing</strong> the best{" "}
              <strong>data-scientists team </strong>
              using AI tools and applying statistical algorithms, we can
              transform your data into valuable information for Decision Making.
            </span>
          )}
        </p>
        <p>
          {language ? (
            <span>
              Esto hará que tu inversión se recupere de una manera muy veloz y
              tengas el conocimiento de tu negocio que siempre has querido.
            </span>
          ) : (
            <span>
              That will make your investment recover quickly, and you will{" "}
              <strong>get</strong> the knowledge <strong>from</strong> your
              business that you have always wanted.
            </span>
          )}
        </p>
      </div>

      <div className="cuestionario">
        <JotformEmbed src="https://form.jotform.com/221896098270061" />
      </div>

      <div className="container-fluid container-md p-md-0">
        <div className="watermark-cover mt-4 py-5 text-center">
          <h1>
            {language ? (
              <span>Artículos que te podrían interesar:</span>
            ) : (
              <span>Here are some articles related</span>
            )}
          </h1>
        </div>
        <div className="external-links d-block d-md-flex justify-content-around my-4">
          <div className="card mb-4">
            <img src="images/link-ds-1.png" alt="vista previa del sitio"></img>
            <a href="https://stat.mit.edu/news/a-tool-for-predicting-the-future/">
              <div className="card-body">
                <h5 class="card-title">A tool for predicting the future</h5>
                <p class="card-text">
                  Researchers design a user-friendly interface that helps
                  nonexperts make forecasts using data collected over time.
                </p>
                <span>stat.mit.edu</span>
              </div>
            </a>
          </div>
          <div className="card mb-4">
            <img src="images/link-ds-2.png" alt="vista previa del sitio"></img>
            <a href="https://news.mit.edu/2022/ai-predictions-human-trust-0119">
              <div className="card-body">
                <h5 class="card-title">
                  When should someone trust an AI assistant’s predictions?
                </h5>
                <p class="card-text">
                  Researchers have created a method to help workers collaborate
                  with artificial intelligence systems.
                </p>
                <span>news.mit.edu</span>
              </div>
            </a>
          </div>
          <div className="card mb-4">
            <img src="images/link-ds-3.png" alt="vista previa del sitio"></img>
            <a href="https://www.elimparcial.com/tijuana/columnas/Que-es-la-ciencia-de-datos-o-Data-science-20220621-0003.html">
              <div className="card-body">
                <h5 class="card-title">
                  ¿Qué es la ciencia de datos? o ‘Data science’
                </h5>
                <p class="card-text">
                  La ciencia de datos continúa evolucionando como uno de los
                  temas más importantes sobre todo en marketing.
                </p>
                <span>elimparcial.com</span>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DS;
