import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./components/Home";
import DS from "./components/DS";
import Layout from "./components/Layout";
import BI from "./components/BI";
import PM from "./components/PM";
import CO from "./components/CO";
import React from "react";
import {
  RecoilRoot,
  useRecoilState,
} from "recoil";
import { languageAtom } from "./Atoms";

function App() {
  return (
    <RecoilRoot>
      {/* <ToggleLanguage /> */}
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />}></Route>
            <Route exact path="/DS" element={<DS />}></Route>
            <Route path="/BI" element={<BI />}></Route>
            <Route path="/PM" element={<PM />}></Route>
            <Route path="/CO" element={<CO />}></Route>
          </Route>
        </Routes>
      </BrowserRouter>
    </RecoilRoot>
  );
}

// function ToggleLanguage() {
//   const [language, setLanguage] = useRecoilState(languageAtom);
//   return (
//     <div>
//       <button className="toggleLanguage" onClick={() => setLanguage(!language)}>
//         {language ? <span>English</span> : <span>Español</span>}
//       </button>
//     </div>
//   );
// }

export default App;
