import React from "react";
import { useRecoilState } from "recoil";
import { languageAtom } from "../Atoms";
import { Link } from "react-router-dom";
import AutoplayVideo from "./AutoplayVideo";

export default function Header(props) {
  const [language] = useRecoilState(languageAtom);
  return (
    <header id="inicio" className="container-fluid position-relative p-0">
      <div className="title-container container-fluid d-flex flex-column align-items-center justify-content-center text-center px-3 pb-5">
        <h1 className="display-2 px-2">
          <img
            className="me-1 mb-2"
            src="images/BAS-Icon.svg"
            alt="BAS icon"
          ></img>
          Business Autonomy Solutions
        </h1>
        <span className=" d-none d-md-block">
          {language ? (
            <span>
              Somos tu mejor opción para optimizar tus tiempos en lo que
              realmente te importa
            </span>
          ) : (
            <span>
              We are the best choice for optimizing your time at what really
              matters to you
            </span>
          )}
        </span>
        <div
          id="phrasesCarousel"
          className="carousel slide"
          data-bs-ride="carousel"
        >
          <button
            className="carousel-control-prev"
            type="button"
            data-bs-target="#phrasesCarousel"
            data-bs-slide="prev"
          >
            <span
              className="carousel-control-prev-icon"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Previous</span>
          </button>
          <div className="carousel-inner">
            <div className="carousel-item active">
              <h1 className="display-6">
                {language ? (
                  <span>
                    ¿Tienes muchos datos, pero no los has podido aprovechar para
                    tomar decisiones?
                  </span>
                ) : (
                  <span>
                    Do you have a lot of data but have not been able to take
                    advantage of it when making decisions?
                  </span>
                )}
              </h1>
              <Link to={"/DS"}>
                {language ? <span>Conoce más</span> : <span>Learn more</span>}
              </Link>
            </div>
            <div className="carousel-item">
              <h1 className="display-6">
                {language ? (
                  <span>
                    ¿Tienes sistemas en tu empresa que generan datos, pero es
                    muy difícil procesarlos?
                  </span>
                ) : (
                  <span>
                    Do you have systems in your company that generate data, but
                    it is too difficult to process them?
                  </span>
                )}
              </h1>
              <Link to={"/DS"}>
                {language ? <span>Conoce más</span> : <span>Learn more</span>}
              </Link>
            </div>
            <div className="carousel-item">
              <h1 className="display-6">
                {language ? (
                  <span>¿Necesita información para predecir tus ventas?</span>
                ) : (
                  <span>Do you need information to predict your sales?</span>
                )}
              </h1>
              <Link to={"/DS"}>
                {language ? <span>Conoce más</span> : <span>Learn more</span>}
              </Link>
            </div>
            <div className="carousel-item">
              <h1 className="display-6">
                {language ? (
                  <span>
                    ¿Requieres tener un sistema para reabastecer tus inventarios
                    y así no negar producto?
                  </span>
                ) : (
                  <span>
                    Do you need a system to replenish your inventories and thus
                    not deny products?
                  </span>
                )}
              </h1>
              <Link to={"/DS"}>
                {language ? <span>Conoce más</span> : <span>Learn more</span>}
              </Link>
            </div>
            <div className="carousel-item">
              <h1 className="display-6">
                {language ? (
                  <span>
                    ¿Has querido tener un sistema para abastecer los diferentes
                    puntos de venta o de distribución?
                  </span>
                ) : (
                  <span>
                    Have you ever wanted to have a system which supplies
                    different points of sale or distribution?
                  </span>
                )}
              </h1>
              <Link to={"/DS"}>
                {language ? <span>Conoce más</span> : <span>Learn more</span>}
              </Link>
            </div>
            <div className="carousel-item">
              <h1 className="display-6">
                {language ? (
                  <span>Las decisiones se toman con información</span>
                ) : (
                  <span>Decisions are made with information</span>
                )}
              </h1>
              <Link to={"/DS"}>
                {language ? <span>Conoce más</span> : <span>Learn more</span>}
              </Link>
            </div>
            <div className="carousel-item">
              <h1 className="display-6">
                {language ? (
                  <span>
                    ¿Tienes varios softwares en tu empresa y no puedes revisar
                    los reportes en un solo lugar?
                  </span>
                ) : (
                  <span>
                    Do you have a number of software systems in your company,
                    and you cannot go through reports in just one place?
                  </span>
                )}
              </h1>
              <Link to={"/BI"}>
                {language ? <span>Conoce más</span> : <span>Learn more</span>}
              </Link>
            </div>
            <div className="carousel-item">
              <h1 className="display-6">
                {language ? (
                  <span>
                    ¿Haces reuniones con tu equipo y a menudo se pierde el
                    control de los pendientes?
                  </span>
                ) : (
                  <span>
                    Do you hold meetings with your team and often lose control
                    of pending issues?
                  </span>
                )}
              </h1>
              <Link to={"/BI"}>
                {language ? <span>Conoce más</span> : <span>Learn more</span>}
              </Link>
            </div>
            <div className="carousel-item">
              <h1 className="display-6">
                {language ? (
                  <span>
                    ¿Tienes sistemas en tu empresa que generan datos, pero es
                    muy difícil procesarlos?
                  </span>
                ) : (
                  <span>
                    Do you have systems in your company that generate data, but
                    it is too difficult to process them?
                  </span>
                )}
              </h1>
              <Link to={"/BI"}>
                {language ? <span>Conoce más</span> : <span>Learn more</span>}
              </Link>
            </div>
            <div className="carousel-item">
              <h1 className="display-6">
                {language ? (
                  <span>
                    ¿Tus reuniones se hacen con información de la semana pasada?
                  </span>
                ) : (
                  <span>
                    Are your meetings done with information from the previous
                    week?
                  </span>
                )}
              </h1>
              <Link to={"/BI"}>
                {language ? <span>Conoce más</span> : <span>Learn more</span>}
              </Link>
            </div>
            <div className="carousel-item">
              <h1 className="display-6">
                {language ? (
                  <span>
                    ¿Haces reuniones con tu equipo y a menudo se pierde el
                    control de los pendientes?
                  </span>
                ) : (
                  <span>
                    Do you hold meetings with your team and often lose control
                    of pending issues?
                  </span>
                )}
              </h1>
              <Link to={"/PM"}>
                {language ? <span>Conoce más</span> : <span>Learn more</span>}
              </Link>
            </div>
            <div className="carousel-item">
              <h1 className="display-6">
                {language ? (
                  <span>¿Sabes darle seguimiento a tus proyectos?</span>
                ) : (
                  <span>Do you know how to track your projects?</span>
                )}
              </h1>
              <Link to={"/PM"}>
                {language ? <span>Conoce más</span> : <span>Learn more</span>}
              </Link>
            </div>
            <div className="carousel-item">
              <h1 className="display-6">
                {language ? (
                  <span>
                    ¿Tienes indicadores que te digan si tus proyectos van bien?
                  </span>
                ) : (
                  <span>
                    Do you have indicators that tell you whether your projects
                    are heading the right direction?
                  </span>
                )}
              </h1>
              <Link to={"/PM"}>
                {language ? <span>Conoce más</span> : <span>Learn more</span>}
              </Link>
            </div>
            <div className="carousel-item">
              <h1 className="display-6">
                {language ? (
                  <span>
                    ¿Conocen tus colaboradores en qué momento deben interactuar
                    en los proyectos?
                  </span>
                ) : (
                  <span>
                    Do your collaborators know when they should interact in the
                    projects?
                  </span>
                )}
              </h1>
              <Link to={"/PM"}>
                {language ? <span>Conoce más</span> : <span>Learn more</span>}
              </Link>
            </div>
            <div className="carousel-item">
              <h1 className="display-6">
                {language ? (
                  <span>
                    ¿Tienes una idea de hacia dónde quieres llegar con tu
                    empresa, pero no has definido una estrategia?
                  </span>
                ) : (
                  <span>
                    Do you have an clear idea of ​​where you want to head with
                    your company but haven't defined a strategy?
                  </span>
                )}
              </h1>
              <Link to={"/CO"}>
                {language ? <span>Conoce más</span> : <span>Learn more</span>}
              </Link>
            </div>
            <div className="carousel-item">
              <h1 className="display-6">
                {language ? (
                  <span>
                    ¿Tienes socios con los que no te puedes poner de acuerdo?
                  </span>
                ) : (
                  <span>Do you have partners with whom you cannot agree?</span>
                )}
              </h1>
              <Link to={"/CO"}>
                {language ? <span>Conoce más</span> : <span>Learn more</span>}
              </Link>
            </div>
            <div className="carousel-item">
              <h1 className="display-6">
                {language ? (
                  <span>
                    ¿Sabes tomar decisiones con los estados financieros de tu
                    empresa?
                  </span>
                ) : (
                  <span>
                    Do you know how to make decisions according to the financial
                    statements of your company’s?
                  </span>
                )}
              </h1>
              <Link to={"/CO"}>
                {language ? <span>Conoce más</span> : <span>Learn more</span>}
              </Link>
            </div>
            <div className="carousel-item">
              <h1 className="display-6">
                {language ? (
                  <span>¿Sabes cómo crear valor en tu empresa?</span>
                ) : (
                  <span>Do you know how to give value to your company?</span>
                )}
              </h1>
              <Link to={"/CO"}>
                {language ? <span>Conoce más</span> : <span>Learn more</span>}
              </Link>
            </div>
          </div>

          <button
            className="carousel-control-next"
            type="button"
            data-bs-target="#phrasesCarousel"
            data-bs-slide="next"
          >
            <span
              className="carousel-control-next-icon"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Next</span>
          </button>
        </div>
        {/* <a href="#servicios" type="button" className="my-2 py-3 px-4">
          Conoce nuestro servicios
        </a> */}
      </div>
      {/* <img className="img-fluid vh-100" src="images/oficina-1.jpg"></img> */}
      <div className="background-video vh-100 overflow-hidden">
        <AutoplayVideo></AutoplayVideo>
        {/* <div className="video-cover"></div> */}
      </div>
    </header>
  );
}
